import React from "react";
import "../Animation.scss";
const Wave = () => {
	return (
		<div className="space">
			<div className="star1"></div>
			<div className="star2"></div>
			<div className="star3"></div>
			<div className="star4"></div>
		</div>
	);
};

export default Wave;
