/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ArrowDown } from "../Icons";
import SocialIcons from "./SocialIcons";
import Wave from "./Wave";

const Banner = () => {
	return (
		<>
			<section className="banner-section">
				<Wave />
				<div className="container">
					<div className="banner-wrapper">
						<div className="banner-content">
							<h1 className="title text-white">
								software <span className="text-base">focusing on</span>{" "}
								your business
							</h1>
							<a href="#" className="cmn-btn">
								Get in Touch
							</a>
						</div>
						<div
							className="arrow-down"
							style={{
								margin: "48px 0",
								textAlign: "center",
								width: "100%",
							}}
						>
							<ArrowDown />
						</div>
						<div className="d-md-none">
							<BannerRightText />
						</div>
						<SocialIcons />
					</div>
				</div>
			</section>
			<div className="d-md-block d-none bg-section">
				<BannerRightText />
			</div>
		</>
	);
};

export const BannerRightText = () => {
	return (
		<section className="banner-right">
			<div className="banner-right-text">
				<div className="content">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
					hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus
					rhoncus pulvinar aliquam
				</div>
			</div>
		</section>
	);
};

export default Banner;
