/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../assets/img/logo.svg";
import useScrollPosition from "../hooks/useScroll";
import { ClearIcon, Hamburger } from "../Icons";
import SocialIcons from "./SocialIcons";
const Header = () => {
	const isScroll = useScrollPosition();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	return (
		<>
			<header
				className={`header-section ${isScroll > 40 ? "fixed" : ""} ${
					isMenuOpen ? "active" : ""
				}`}
			>
				<div className="container">
					<div className="header-wrapper">
						<a href="#" className="logo">
							<img src={logo} alt="" />
						</a>
						<div className="d-lg-none">
							<span
								type="button"
								onClick={() => setIsMenuOpen(!isMenuOpen)}
							>
								{isMenuOpen ? <ClearIcon /> : <Hamburger />}
							</span>
						</div>
						<div className={`header-menu ${isMenuOpen ? "active" : ""}`}>
							<ul className="menu">
								<li>
									<a
										href="#"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Home
									</a>
								</li>
								<li>
									<a
										href="/#what-we-do"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										What We Do
									</a>
								</li>
								<li>
									<a
										href="/#project"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Projects
									</a>
								</li>
								<li>
									<a
										href="/#contact"
										className="cmn-btn ms-lg-2"
										onClick={() => setIsMenuOpen(!isMenuOpen)}
									>
										Get In Touch
									</a>
								</li>
							</ul>
							<div className="d-lg-none social-follow text-center">
								<h3 className="font-light follow">Follow Us</h3>
								<SocialIcons />
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
