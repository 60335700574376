import React from "react";
import img from "../assets/img/react.png";
const Technologies = () => {
	return (
		<>
			<div className="container">
				<div className="seperate-line"></div>
			</div>
			<section className="technologies-section">
				<div className="container">
					<h2
						className="section-title text-center mx-auto"
						style={{ maxWidth: "500px" }}
					>
						Technologies we work with
					</h2>
					<div className="row g-3 g-lg-4 justify-content-center">
						{["", "", "", "", "", "", "", "", "", ""].map((item, i) => (
							<div className="col-4 col-sm-3 col-md-2" key={i}>
								<div className="tech-item">
									<img src={img} alt="" />
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default Technologies;
