/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import img1 from "../assets/img/highlight/1.png";
import img2 from "../assets/img/highlight/2.png";
import img3 from "../assets/img/highlight/3.png";
const data = [
	{
		img: img1,
		title: "AI & Machine Learning",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam",
	},
	{
		img: img2,
		title: "AI & Machine Learning",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam",
	},
	{
		img: img3,
		title: "AI & Machine Learning",
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam",
	},
];
const ProjectHighLight = () => {
	return (
		<section className="highlight-section" id="project">
			<div className="container">
				<h2 className="section-title">Project highlight</h2>
				<div className="project-highlight-wrapper">
					{data?.map(({ img, title, desc }, i) => (
						<div className="project-item" key={i}>
							<img src={img} alt="" />
							<div className="cont">
								<h4 className="subtitle">{title}</h4>
								<p>{desc}</p>
								<a href="/#contact" className="cmn-btn">
									Get In Touch
								</a>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default ProjectHighLight;
