import React from "react";
import { Facebook, Instagram, LinkedinIn, Twitter } from "../Icons";

const data = [
	{
		icon: <Facebook />,
		link: "https://facebook.com",
	},
	{
		icon: <Instagram />,
		link: "https://instagram.com",
	},
	{
		icon: <Twitter />,
		link: "https://twitter.com",
	},
	{
		icon: <LinkedinIn />,
		link: "https://linkedinIn.com",
	},
];
const SocialIcons = () => {
	return (
		<>
			<ul className="social-icons">
				{data?.map(({ icon, link }, i) => (
					<li key={i}>
						<a href={link} target="_blank" rel="noreferrer">
							{icon}
						</a>
					</li>
				))}
			</ul>
		</>
	);
};

export default SocialIcons;
