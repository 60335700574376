import React from "react";
import { AiMachineIcon, CodeIcon, ProtoTypingIcon } from "../Icons";

const data = [
	{
		icon: <ProtoTypingIcon />,
		title: "Rapid Prototyping",
		desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam`,
	},
	{
		icon: <AiMachineIcon />,
		title: "AI & Machine Learning",
		desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam`,
	},
	{
		icon: <CodeIcon />,
		title: "Web & Mobile Applications",
		desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam hendrerit nisi sed sollicitudin pellentesque. Nunc posuere purus rhoncus pulvinar aliquam`,
	},
];

const WhatWeDo = () => {
	return (
		<section className="what-we-do-section bg-section">
			<div
				id="what-we-do"
				style={{ position: "absolute", top: "-80px" }}
			></div>
			<div className="container">
				<h2 className="section-title">What we do</h2>
				<div className="what-we-do-wrapper">
					{data?.map(({ icon, title, desc }, i) => (
						<div className="what-we-do-card" key={i}>
							<div className="position-relative">
								<div className="top">
									{icon && <div className="icon">{icon}</div>}
									{title && <h5 className="subtitle">{title}</h5>}
								</div>
								<div className="bottom">
									{desc && <div className="desc">{desc}</div>}
								</div>
							</div>
							<div className="radial"></div>
						</div>
					))}
				</div>
			</div>
			<div className="what-we-do-section-shape">&nbsp;</div>
		</section>
	);
};

export default WhatWeDo;
