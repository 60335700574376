/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import contactGlobe from "../assets/img/globe.svg";
import logo from "../assets/img/logo.svg";
import SocialIcons from "./SocialIcons";
const Footer = () => {
	return (
		<footer>
			<div className="container">
				<Contact />
				<div className="footer-top-wrapper">
					<div className="logo-area">
						<a href="#0" className="logo">
							<img src={logo} alt="" />
						</a>
						<div>
							SovTech is an award-winning, end-to-end bespoke software
							development company in the UK that designs, builds, deploys
							and maintains mobile and web applications.
						</div>
					</div>
					<div className="social-follow text-center mt-lg-4">
						<h3 className="font-light follow">Follow Us</h3>
						<SocialIcons />
					</div>
				</div>
				<div className="footer-bottom">All Rights Reserved 2023</div>
			</div>
		</footer>
	);
};
export const Contact = () => {
	return (
		<>
			<div className="contact-wrapper" id="contact">
				<div className="row g-4 flex-wrap-reverse align-items-center">
					<div className="col-md-6 pe-lg-5">
						<form className="contact-area" style={{ maxWidth: "445px" }}>
							<h2 className="section-title">Get in touch</h2>
							<div className="mb-4">
								<input
									type="text"
									placeholder="Name"
									className="form-control"
								/>
							</div>
							<div className="mb-4">
								<input
									type="text"
									placeholder="Email"
									className="form-control"
								/>
							</div>
							<div className="mb-4">
								<input
									type="text"
									placeholder="Subject"
									className="form-control"
								/>
							</div>
							<div className="mb-4 pt-md-5">
								<textarea
									className="form-control"
									placeholder="Message"
								></textarea>
							</div>
							<button className="cmn-btn" type="submit">
								Get In Touch
							</button>
						</form>
					</div>
					<div className="col-md-6">
						<div className="globe">
							<img src={contactGlobe} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Footer;
