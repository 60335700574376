import React from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProjectHighLight from "../components/ProjectHighLight";
import Technologies from "../components/Technologies";
import WhatWeDo from "../components/WhatWeDo";

const Home = () => {
	return (
		<>
			<Header />
			<Banner />
			<WhatWeDo />
			<ProjectHighLight />
			<Technologies />
			<Footer />
		</>
	);
};

export default Home;
